<template>
  <div class="bg">
    <Spin v-if="loading" fix></Spin>
    <div class="main-title">
      <span v-if="!type">
        Showing results for:
      </span>
      <span v-if="type === 'product'">
        Showing {{ product.total }} results for:
      </span>
      <span v-if="type === 'company'">
        Showing {{ company.total }} results for:
      </span>
      <span class="bold">"{{ q }}"</span>
    </div>

    <div class="search">
      <div class="search-box">
        <div class="input-box">
          <Icon type="ios-search" class="search-icon" />
          <input
            v-model="filter.search"
            v-on:keyup.enter="handleSearch"
            type="text"
            placeholder="Search for a product, company, category or application, and more..."
          />
        </div>
        <div class="btn" @click="handleSearch">Search</div>
        <div class="btn clearbtn" shape="circle" @click="clearSearch">
          Clear
        </div>
        <!-- <div
          v-if="role === 'attendee'"
          class="btn clearbtn2"
          @click="showInquire = true"
        >
          Inquire Sourcing
        </div> -->
      </div>
    </div>

    <div class="search" style="border-bottom:none">
      <div class="words">
        <div class="info-title">
          Popular Search Terms
        </div>
        <div class="terms-box">
          <div
            @click="chooseWord(item.key_word)"
            class="terms"
            v-for="(item, index) in words"
            :key="index"
          >
            {{ item.key_word }}
          </div>
        </div>
      </div>
    </div>

    <div class="list-box">
      <div v-if="!this.type || this.type === 'product'" class="list">
        <div class="title">
          Products
          <div class="inconClose">
            <Icon
              type="ios-arrow-up"
              v-if="proClose"
              @click="proClose = false"
            />
            <Icon type="ios-arrow-down" v-else @click="proClose = true" />
          </div>
        </div>

        <div class="cards-product" v-if="!proClose">
          <div class="empty" v-if="product.list.length === 0">
            No result found
          </div>

          <div v-else>
            <Row type="flex" justify="start" :gutter="16">
              <i-col
                :key="item.id"
                v-for="item in product.list"
                style="width:20%"
              >
                <div class="item">
                  <a
                    v-if="item.redirect_url"
                    class="imgWrap"
                    :href="item.redirect_url"
                    target="_blank"
                  >
                    <div class="img-box">
                      <img alt="" class="avatar" :src="item.images[0].url" />
                    </div>
                    <div class="user-avatar">
                      <img
                        alt=""
                        class="avatar"
                        :src="item.company.avatar && item.company.avatar.url"
                      />
                    </div>
                  </a>
                  <router-link
                    v-else
                    class="imgWrap"
                    :to="
                      item.company.level === 'Basic'
                        ? `/company/${item.company_id}`
                        : `/products/${item.id}`
                    "
                  >
                    <div class="img-box">
                      <img alt="" class="avatar" :src="item.images[0].url" />
                    </div>
                    <div class="user-avatar">
                      <img
                        alt=""
                        class="avatar"
                        :src="item.company.avatar && item.company.avatar.url"
                      />
                    </div>
                  </router-link>

                  <router-link
                    :to="
                      `/search?q=${encodeURIComponent(
                        item.marketing_name
                      )}&type=product&marketing_name=${encodeURIComponent(
                        item.marketing_name
                      )}`
                    "
                  >
                    <div class="user">
                      <div class="user-name">
                        {{ item.marketing_name || "--" }}
                      </div>
                      <div
                        class="product-name"
                        @click="$router.push(`/company/${item.company_id}`)"
                      >
                        {{ item.company.company_name }}
                      </div>
                    </div>
                  </router-link>

                  <div class="name">
                    <p class="tit">PRODUCT CATEGORY</p>
                    <router-link
                      :to="
                        `/search?q=${encodeURIComponent(
                          item.product_type
                        )}&type=product&product_type=${encodeURIComponent(
                          item.product_type
                        )}`
                      "
                    >
                      <div class="product-type">
                        {{ item.product_type || "--" }}
                      </div></router-link
                    >
                  </div>
                  <div class="tags">
                    <div
                      v-if="!item.tags || (item.tags && item.tags.length === 0)"
                    >
                      No tags
                    </div>

                    <div
                      class="tag"
                      :style="{ background: tag.color }"
                      v-for="tag in item.tags"
                      :key="tag.id"
                    >
                      {{ tag.name }}
                    </div>
                  </div>
                </div>
              </i-col>
            </Row>

            <div class="page">
              <Page
                v-if="product.total && product.total > 0"
                :page-size="limit"
                :total="product.total"
                show-elevator
                show-total
                @on-change="pageChange($event, 'product')"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!this.type || this.type === 'company'" class="list">
        <div class="title">
          Companies
          <div class="inconClose">
            <Icon
              type="ios-arrow-up"
              v-if="comClose"
              @click="comClose = false"
            />
            <Icon type="ios-arrow-down" v-else @click="comClose = true" />
          </div>
        </div>
        <div class="cards-company" v-if="!comClose">
          <div class="empty" v-if="company.list.length === 0">
            No result found
          </div>
          <div v-else>
            <template>
              <Row type="flex" justify="start" :gutter="16">
                <i-col :key="item.id" v-for="item in company.list" span="4">
                  <div class="item">
                    <router-link :to="`/company/${item.id}`">
                      <div class="img-box">
                        <div class="decr"></div>
                        <div
                          class="tag"
                          :style="{
                            background:
                              item.level === 'Diamond'
                                ? '#80DEEA'
                                : item.level === 'Gold'
                                ? '#FFD54F'
                                : '#8D6E63'
                          }"
                        >
                          {{ item.level }}
                        </div>
                        <div class="wrap">
                          <img class="avatar" :src="item.avatar.url" alt="" />
                        </div>
                        <p class="cname">
                          {{ item.company_name | nameFilter }}
                        </p>
                      </div>
                    </router-link>

                    <!-- <div class="name">
                    <div class="product-name com-name">
                      <p>Products</p>
                      <small>{{ item.products_count }}</small>
                    </div>
                    <div class="factory-name com-name">
                      <p>Factories</p>
                      <small>{{ item.factories_count }}</small>
                    </div>
                  </div> -->

                    <div class="tiks">
                      <div class="tik">
                        Products:<span>{{ item.products_count }}</span>
                      </div>
                      <div class="tik">
                        Factories:<span>{{ item.factories_count }}</span>
                      </div>
                    </div>

                    <div class="tiks">
                      <div class="tik">
                        Type:<span>{{ item.business_type || "--" }}</span>
                      </div>
                      <div class="tik">
                        Industry:<span>{{ item.industry | shortFilter }}</span>
                      </div>
                      <div class="tik">
                        Employees:<span>{{ item.company_size }}</span>
                      </div>
                      <div class="tik">
                        Headquarter:<span v-if="item.company_country_info">{{
                          item.company_country_info.name | shortFilter
                        }}</span
                        ><span v-else>"--"</span>
                      </div>
                    </div>

                    <!-- <div class="user">
                    <router-link :to="`/company/${item.id}`">
                      <div class="user-name">View Profile</div>
                    </router-link>
                  </div> -->
                  </div>
                </i-col>
              </Row>
            </template>
            <div class="page">
              <Page
                v-if="company.total && company.total > 0"
                :page-size="limit"
                :total="company.total"
                show-elevator
                show-total
                @on-change="pageChange($event, 'company')"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!this.type || this.type === 'conference'" class="list">
        <div class="title">
          Conferences
          <div class="inconClose">
            <Icon
              type="ios-arrow-up"
              v-if="conClose"
              @click="conClose = false"
            />
            <Icon type="ios-arrow-down" v-else @click="conClose = true" />
          </div>
        </div>

        <div class="cards-conference" v-if="!conClose">
          <div class="empty" v-if="conference.list.length === 0">
            No result found
          </div>

          <div v-else>
            <Row type="flex" justify="start" :gutter="16">
              <i-col
                :key="item.id"
                v-for="item in conference.list"
                style="width:25%"
              >
                <div
                  class="item"
                  @click="$router.push(`/conference/${item.id}`)"
                >
                  <h3>{{ item.title | nameFilter }}</h3>
                  <p class="name">{{ item.host_name }} • {{ item.dateStr }}</p>
                  <p class="status">Not Attending</p>
                  <div class="content" v-html="item.description"></div>
                </div>
              </i-col>
            </Row>

            <div class="page">
              <Page
                v-if="conference.total && conference.total > 0"
                :page-size="limit"
                :total="conference.total"
                show-elevator
                show-total
                @on-change="pageChange($event, 'conference')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _search, _searchPopular } = api;
import moment from "moment";

export default {
  data() {
    return {
      screenWidth: window.innerWidth > 1200,
      proClose: false,
      comClose: false,
      conClose: false,
      limit: 10,
      product: {
        page: 1,
        total: 0,
        list: []
      },

      conference: {
        page: 1,
        total: 0,
        list: []
      },

      company: {
        page: 1,
        total: 0,
        list: []
      },

      words: [],
      filter: {},

      loading: false,
      q: "",
      type: undefined,
      product_type: undefined,
      marketing_name: undefined
    };
  },

  watch: {
    $route(newRoute) {
      const query = newRoute.query;
      this.q = query.q;
      this.type = query.type;
      this.product_type = query.product_type;
      this.marketing_name = query.marketing_name;
      this.search();
    }
  },

  created() {
    const query = this.$route.query;
    this.q = query.q;
    this.type = query.type;
    this.product_type = query.product_type;
    this.marketing_name = query.marketing_name;
    this.search();

    _searchPopular().then(response => {
      const { data } = response;
      this.words = data;
    });
  },

  methods: {
    handleSearch() {
      this.$router.push(`/search?q=${this.filter.search}`);
    },
    clearSearch() {
      this.filter.search = "";
      // this.$router.push(`/search?q=${this.filter.search}`);
    },
    chooseWord(w) {
      this.filter.search = w;
      this.$router.push(`/search?q=${w}`);
    },
    pageChange(page, type) {
      this[type].page = page;
      this.search(type);
    },
    search(searchType) {
      this.loading = true;
      let page = 1;
      if (searchType) {
        page = this[searchType].page;
      }
      _search({
        q: this.q,
        limit: this.limit,
        type: this.type,
        product_type: this.product_type,
        marketing_name: this.marketing_name,
        page
      })
        .then(response => {
          const { data } = response;

          if (searchType) {
            if (searchType === "product") {
              if (data.product) {
                // this.product.page = data.product.current_page;
                this.product.total = data.product.total;
                this.product.list = data.product.data;
              }
            }
            if (searchType === "company") {
              if (data.company) {
                // this.company.page = data.company.current_page;
                this.company.total = data.company.total;
                this.company.list = data.company.data;
              }
            }
            if (searchType === "conference") {
              if (data.conference) {
                // this.conference.page = data.conference.current_page;
                this.conference.total = data.conference.total;

                this.conference.list = data.conference.data.map(item => {
                  const start = moment
                    .utc(item.start_date)
                    .local()
                    .format("MMM D,YYYY");

                  const end = moment
                    .utc(item.end_date)
                    .local()
                    .format("MMM D,YYYY");

                  item.dateStr = `${start}-${end}`;
                  return item;
                });
              }
            }
          } else {
            if (data.conference) {
              // this.conference.page = data.conference.current_page;
              this.conference.total = data.conference.total;

              this.conference.list = data.conference.data.map(item => {
                const start = moment
                  .utc(item.start_date)
                  .local()
                  .format("MMM D,YYYY");

                const end = moment
                  .utc(item.end_date)
                  .local()
                  .format("MMM D,YYYY");

                item.dateStr = `${start}-${end}`;
                return item;
              });
            }

            if (data.company) {
              // this.company.page = data.company.current_page;
              this.company.total = data.company.total;
              this.company.list = data.company.data;
            }

            if (data.product) {
              // this.product.page = data.product.current_page;
              this.product.total = data.product.total;
              this.product.list = data.product.data;
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {
    nameFilter(val) {
      if (val.length > 26) return val.substring(0, 26) + "...";
      else return val;
    },
    shortFilter(val) {
      if (val && val.length > 16) return val.substring(0, 16) + "...";
      else if (val) return val;
      else return "--";
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  background: #fff;
  position: relative;
}
.main-title {
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;

  color: #333;
  font-size: 1.8em;
  .bold {
    font-weight: bold;
  }
}

.search {
  background: #fff;
  border-bottom: 1px solid #ccc;
  padding: 16px 20px;
  .search-box {
    display: flex;
    align-items: center;
    height: 35px;
    // width: 600px;
    .input-box {
      position: relative;
      flex: 1;
      // width: 600px;
      height: 100%;
      background: #fff;
      line-height: initial;
      padding-left: 40px;
      padding-right: 20px;
      border: 1px solid #dfdfdf;
      border-right: none;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      .search-icon {
        position: absolute;
        top: 7px;
        left: 10px;
        font-size: 20px;
        color: #888;
      }
      input {
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        border: none;
        color: #444;
        caret-color: #888;

        &::-webkit-input-placeholder {
          color: #bdcada;
        }
        &:-moz-placeholder {
          color: #bdcada;
        }
        &::-moz-placeholder {
          color: #bdcada;
        }
        &:-ms-input-placeholder {
          color: #bdcada;
        }
      }
    }
    .tit {
      padding: 0 10px;
      margin-left: 10px;
      &.first {
        padding-left: 0;
        margin-left: 0;
      }
    }
    .ivu-btn-circle {
      color: @primary-color;
      border: 1px solid @primary-color;
      margin-left: 20px;
    }
    .btn {
      cursor: pointer;
      background: #fff;
      color: @primary-color;
      border: 1px solid @primary-color;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-self: center;
      line-height: initial;
      height: 100%;
    }
    .clearbtn {
      margin-left: 10px;
      color: #666;
      border: 1px solid #ddd;
      border-radius: 20px;
    }
    .clearbtn2 {
      margin-left: 190px;
      color: #fff;
      background-color: @primary-color;
      border: 1px solid @primary-color;
      border-radius: 6px;
    }
    .type-wrap {
      position: absolute;
      right: 20px;
      color: #ffca94;
      font-size: 20px;
      cursor: pointer;
      /deep/ .ivu-icon-ios-list {
        position: relative;
        top: 3px;
        font-size: 32px;
        font-weight: bold;
      }
      .cur {
        color: @primary-color;
      }
    }
  }
  .words {
    .info-title {
      color: #333;
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .terms-box {
      display: flex;

      .terms {
        color: @primary-color;
        border: 1px solid @primary-color;
        border-radius: 4px;
        padding: 4px 10px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

.list-box {
  // padding: 20px 0;
  .list {
    // margin-bottom: 20px;
    .title {
      padding: 15px 20px;
      font-size: 1.6em;
      font-weight: bold;
      color: #333;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }

    .inconClose {
      float: right;
      cursor: pointer;
      font-size: 24px;
      color: #ff6600;
    }

    .empty {
      line-height: 200px;
      text-align: center;
      font-weight: bold;
      color: #666;
      font-size: 16px;
    }

    .cards-product {
      min-height: 200px;
      position: relative;
      padding: 20px;
      padding-bottom: 30px;
      background-color: #f8f8f8;
      .item {
        position: relative;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        background: #fff;
        box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.149019607843137);
        border-radius: 5px;
        .imgWrap {
          position: relative;
          display: block;
        }
        .img-box {
          width: 100%;
          height: 0px;
          position: relative;
          padding-bottom: 56.25%;
          overflow: hidden;
          border-bottom: 1px solid #ccc;
          background: #eee;
          border-radius: 4px;
          .avatar {
            display: block;
            width: 100%;
          }
        }

        .name {
          padding: 10px;
          // min-height: 80px;
          border-bottom: 1px solid #ccc;
          color: #999;
          .tit {
            font-weight: bold;
            font-size: 12px;
          }
          .product-name {
            font-size: 14px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .product-type {
            font-size: 14px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .user-avatar {
          position: absolute;
          bottom: -15px;
          left: 10px;
          z-index: 2;
          width: 75px;
          height: 75px;
          background: #eee;
          border: 1px solid #f2f2f2;
          border-radius: 50%;
          margin-right: 10px;
          overflow: hidden;
          .avatar {
            display: block;
            width: 100%;
          }
        }

        .user {
          height: 75px;
          padding: 12px 10px 0;
          margin-top: 5px;
          // margin-bottom: 10px;
          // border-bottom: 1px solid #ccc;
          // display: flex;
          align-items: center;
          .user-name {
            line-height: 20px;
            font-size: 16px;
            color: #000;
            font-weight: bold;
            overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }
          .product-name {
            padding-top: 5px;
            font-size: 14px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .tags {
          padding: 10px;
          height: 50px;
          display: flex;
          align-items: center;
          overflow: hidden;
          .tag {
            color: #fff;
            border-radius: 3px;
            font-size: 12px;
            margin-right: 4px;
            height: 25px;
            display: flex;
            align-items: center;
            padding: 0 5px;
            font-weight: bold;
          }
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
      }
    }

    .cards-company {
      min-height: 200px;
      position: relative;
      padding: 20px;
      padding-bottom: 30px;
      background-color: #f8f8f8;
      .item {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 6px;
        background: #fff;
        box-shadow: 3px 3px 6px #ddd;
        .img-box {
          position: relative;
          width: 100%;
          overflow: hidden;
          border-bottom: 1px solid #ccc;
          text-align: left;
          padding: 0 10px;
          margin-bottom: 5px;
          // padding-bottom: 20px;
          .decr {
            position: absolute;
            top: 0;
            right: 92px;
            border-bottom: 28px solid #fff;
            border-right: 28px solid transparent;
            z-index: 2;
          }
          .tag {
            position: absolute;
            top: 0;
            right: 0;
            border-top-right-radius: 4px;
            color: #fff;
            width: 120px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            padding-left: 28px;
            &::before {
              content: ""; /* To generate the box */
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: -1;
              background: #58a;
              transform: skew(45deg);
            }
          }
          .wrap {
            min-width: 60px;
            height: 60px;
            margin: 15px auto 10px;
            overflow: hidden;
            .avatar {
              height: 100%;
            }
          }
          .cname {
            width: 100%;
            height: 38px;
            font-size: 15px;
            color: #444;
            font-weight: bold;
            line-height: 16px;
          }
        }

        .name {
          display: flex;
          border-bottom: 1px solid #ccc;
          color: #666;
          .com-name {
            flex: 0 0 50%;
            padding: 10px 0;
            font-size: 12px;
            color: #888;
            text-align: center;
            small {
              display: block;
              text-align: left;
              margin: 0 auto;
              width: 50px;
              color: #000;
            }
          }
          .product-name {
            border-right: 1px solid #ccc;
          }
        }
        .tiks {
          font-size: 12px;
          // border-bottom: 1px solid #ccc;
          color: #888;
          // padding: 5px 0;
          .tik {
            padding: 3px 10px;
            font-weight: bold;
            span {
              // float: right;
              padding-left: 4px;
              color: #333;
              font-weight: normal;
            }
          }
        }
        .user {
          padding: 10px;
          text-align: center;
          .user-name {
            font-size: 12px;
            color: @primary-color;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .tags {
          padding: 10px;
          height: 50px;
          display: flex;
          align-items: center;
          overflow: hidden;
          .tag {
            color: #fff;
            border-radius: 3px;
            font-size: 12px;
            margin-right: 4px;
            height: 25px;
            display: flex;
            align-items: center;
            padding: 0 5px;
            font-weight: bold;
          }
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
      }
    }

    .cards-conference {
      min-height: 200px;
      position: relative;
      padding: 20px;
      padding-bottom: 30px;
      background-color: #f8f8f8;
      .item {
        position: relative;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        background: #fff;
        box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.149019607843137);
        border-radius: 5px;
        cursor: pointer;
        h3 {
          padding: 10px;
          padding-bottom: 0;
        }
        .name {
          padding: 0 10px;
          font-size: 13px;
        }
        .status {
          padding: 0 10px;
          font-style: italic;
          color: #999;
          font-size: 13px;
        }
        .content {
          border-top: 1px solid #f8f8f8;
          padding: 10px;
          height: 104px;
          font-size: 12px;
          overflow: hidden;
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
      }
    }

    .page {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
